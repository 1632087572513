#depositFunds {
  .browser-wrapper,
  .info-box {
    border-radius: 8px;
    background-color: $white;
  }

  .browser-wrapper + .info-box {
    margin-top: 20px;
  }

  /deep/ .browser-wrapper {
    padding: 20px 20px 40px;

    h3 {
      margin-bottom: 50px;
    }

    .selection-box {
      border-style: solid;
      border-width: $border-width;
      border-color: $border-color;
      border-radius: 6px;
      padding: 0 20px 40px;
      min-height: 650px;

      .el-tabs {
        position: relative;

        .el-tabs__header:after {
          content: '';
          position: absolute;
          width: calc(100% - 0.2%);
          height: 20px;
          display: block;
          bottom: -110px;
          background-color: $light-gray;
          border-radius: 4px;
        }

        .el-tabs__nav-scroll,
        .el-tabs__nav-wrap {
          overflow: unset;
        }

        .el-tabs__item {
          box-shadow: 0px 24px 3px -24px #b1b1b1;
          background-color: $light-blue;
          width: 19.8%;
          height: 60px;
          font-size: 15px;
          color: $blue;
          line-height: 60px;
          border-radius: 4px 4px 0 0;
          cursor: pointer;
          position: absolute;
          bottom: -90px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
          transition: height 0.5s;
          text-transform: uppercase;

          &:not(:last-child) {
            @include rtl-sass-prop(margin-right, margin-left, 0.2%);
          }

          &:first-child {
            @include rtl-sass-prop(left, right, 0);
          }

          &:nth-child(2) {
            @include rtl-sass-prop(left, right, calc(19.8% + 0.2%));
          }

          &:nth-child(3) {
            @include rtl-sass-prop(left, right, calc((19.8% * 2) + (0.2% * 2)));
          }

          &:nth-child(4) {
            @include rtl-sass-prop(left, right, calc((19.8% * 3) + (0.2% * 3)));
          }

          &:nth-child(5) {
            @include rtl-sass-prop(left, right, calc((19.8% * 4) + (0.2% * 4)));
          }

          &.is-active {
            background-color: $blue;
            box-shadow: 2.007px 2.229px 7.56px 1.44px $gray-blue;
            color: $white;
            height: 102px;
            line-height: 102px;
            border-bottom-color: transparent;
          }
        }
      }
    }
  }

  /deep/ .mobile-wrapper {
    h3 {
      margin-bottom: 10px;
    }

    .mobile-title {
      background-color: $blue;
      color: $white;
      min-width: 170px;
      max-width: 240px;
      margin-bottom: 20px;
      text-align: center;
      line-height: 2.2;
    }

    .mobile-box {
      margin-bottom: 30px;
      display: grid;
      grid-template-columns: repeat(auto-fill, calc((100% - (20px * 4)) / 5));
      column-gap: 20px;
      row-gap: 40px;

      li {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #b8cdea;
        border-radius: 8px;
        cursor: pointer;

        &.isActive {
          background-color: $blue !important;
        }
      }
    }

    .mobile-top {
      border-radius: 8px;
      background-color: $white;
      padding: 20px 20px 40px;
    }

    .link-box {
      margin-top: 0;
    }
  }

  .info-box {
    padding: 20px;

    ul {
      border-style: solid;
      border-width: $border-width;
      border-color: $border-color;
      border-radius: 8px;
      background-color: $white;
      padding: 20px 40px 30px;

      /deep/ li {
        color: rgb(98, 109, 113);
        line-height: 2.25;
        list-style: disc;
        margin-bottom: 10px;
      }
    }
  }

  .mobile-wrapper + .info-box {
    margin-top: 20px;
  }
}

@media (max-width: 1280px) {
  #depositFunds {
    .info-box ul /deep/ li {
      font-size: 12px;
      line-height: 1.8;
    }
  }
}

@media (max-width: 768px) {
  #depositFunds /deep/ .mobile-wrapper .mobile-box li {
    height: 100px;
  }
}

@media (max-width: 550px) {
  #depositFunds /deep/ .mobile-wrapper .mobile-box li {
    height: 70px;

    img {
      width: 70%;
    }
  }
}
